import i18next from 'i18next';
import { featuresService } from '../App';
import { FeatureTypes } from '../services/feature-service/features.enum';
import NotificationsPill from '../views/Partials/design-components/notifications-pill/notificationsPill.component';
import React from 'react';
import './styles.scss';
import CustomizationApiHttpService from '../models/v2/custom-blocks/services/custom-blocks-http.service';

export const displayListsNestedElements = () => {
	const result = [
		{
			name: i18next.t('editorial'),
			customTitle: 'editorial',
			url: '/smp/lists',
			icon: 'fa fa-list-ul',
		},
	];

	if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.AUTOMATIC_LISTS)) {
		result.push({
			name: i18next.t('automated'),
			customTitle: 'automated',
			url: '/smp/automated-lists',
			icon: 'fa fa-list-ul',
		});
	}

	if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.SCHEDULED_LISTS)) {
		result.push({
			name: i18next.t('scheduled'),
			customTitle: 'scheduled_list',
			url: '/smp/scheduled-lists',
			icon: 'fa fa-list-ul',
		});
	}

	result.push({
		name: i18next.t('tags_and_sports'),
		customTitle: 'tags_and_sports',
		url: '/smp/tags-and-sports-connections-lists',
		icon: 'fa fa-list-ul',
	});

	return result;
};

export const displayArticlesNestedElements = (count: number) => {
	const result = [
		{
			name: i18next.t('all_articles'),
			customTitle: 'all_articles',
			url: '/smp/articles',
			icon: 'fa fa-file-text',
		},
	];

	if (
		featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.RSM_ARTICLES) &&
		featuresService.adminIsAuthorizedForExternalRSMArticlesFeature()
	) {
		result.push({
			name: (
				<div
					className='rsm-articles-container'
					onClick={() => {
						CustomizationApiHttpService.postToAdminActivity();
					}}
				>
					{i18next.t('rsm_articles')}
					{count > 0 && <NotificationsPill newItemsCount={count} />}
				</div>
			),
			customTitle: 'rsm_articles',
			url: '/smp/rsm-articles',
			icon: 'fa fa-file-text',
		});
	}

	return result;
};
