import Global from '../views/Partials/Blocky/helpers/global.helpers';
import { displayArticlesNestedElements, displayListsNestedElements } from './nav-helper';

export const nav = (t: any, count: number) => {
	const quickAccess = Global.createQuickAccessMenu(t);

	return {
		items: [
			{
				name: t('dashboard'),
				url: '/dashboard',
				icon: 'icon-speedometer',
			},
			{
				title: true,
				name: t('content'),
				wrapper: {
					element: '',
					attributes: {},
				},
			},
			{
				name: t('articles'),
				customTitle: 'articles',
				icon: 'fa fa-file-text',
				children: displayArticlesNestedElements(count),
			},
			{
				name: t('videos'),
				customTitle: 'videos',
				icon: 'fa fa-play',
				children: [
					{
						name: t('all_videos'),
						url: '/smp/videos',
						icon: 'fa fa-play',
					},
					{
						name: t('jw_videos'),
						customTitle: 'jw_videos',
						url: '/smp/jw-videos',
						icon: 'fa fa-play',
					},
				],
			},
			{
				name: t('galleries'),
				url: '/smp/galleries',
				icon: 'fa fa-camera',
			},
			{
				name: t('images'),
				customTitle: 'images',
				icon: 'fa fa-file-image-o',
				children: [
					{
						name: t('all-images'),
						url: '/smp/images',
						icon: 'fa fa-file-image-o',
					},
					{
						name: 'Playing Surface',
						customTitle: 'playing-surface',
						url: '/smp/playingSurface',
						icon: 'fa fa-file-image-o',
					},
					{
						name: t('imago_images'),
						customTitle: 'imago',
						url: '/smp/imagoImages',
						icon: 'fa fa-file-image-o',
					},
					{
						name: t('getty_images'),
						customTitle: 'getty_images',
						url: '/smp/getty-images',
						icon: 'fa fa-file-image-o',
					},
				],
			},
			{
				name: t('lists'),
				customTitle: 'lists',
				icon: 'fa fa-list-ul',
				children: displayListsNestedElements(),
			},
			{
				name: t('wiki_pages'),
				customTitle: 'wiki-pages',
				url: '/smp/wiki-pages',
				icon: 'fa fa-book',
			},
			{
				name: t('live_blogs'),
				customTitle: 'live-blogs',
				url: '/smp/live-blogs',
				icon: 'fa-solid fa-newspaper',
			},
			{
				title: true,
				name: t('entities'),
				wrapper: {
					element: '',
					attributes: {},
				},
			},
			{
				name: t('custom_entities'),
				customTitle: 'custom-entities',
				url: '/smp/custom-entities',
				icon: 'fa-solid fa-display',
			},
			// {
			// 	name: t('polls'),
			// 	url: '/smp/polls',
			// 	icon: 'fa fa-question-circle-o',
			// },
			...quickAccess,
			{
				title: true,
				name: t('other'),
				wrapper: {
					// optional wrapper object
					element: '', // required valid HTML5 element tag
					attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
				},
				class: '', // optional class names space delimited list for title item ex: "text-center"
			},
			{
				name: t('audit_log'),
				customTitle: 'audit_log',
				url: '/smp/audit-log',
				icon: 'fa-solid fa-book',
			},
			{
				name: t('content_analytics'),
				customTitle: 'content_analytics',
				url: '/smp/content-analytics',
				icon: 'fa fa-line-chart',
			},
			{
				name: t('news-tracker'),
				customTitle: 'news-tracker',
				url: '/smp/news-tracker',
				icon: 'fa fa-newspaper-o',
			},
			{
				name: t('categories'),
				url: '/smp/categories',
				icon: 'fa fa-list',
			},
			{
				name: t('tags'),
				url: '/smp/tags',
				icon: 'fa fa-tags',
			},
			{
				name: t('authors'),
				url: '/smp/authors',
				icon: 'fa fa-user-circle-o',
			},
			{
				name: t('banners'),
				url: '/smp/banners',
				icon: 'fa fa-square',
			},

			{
				title: true,
				name: t('sport'),
				customTitle: 'sport',
				wrapper: {
					element: '',
					attributes: {},
				},
				class: '',
			},
			{
				name: t('football'),
				customTitle: 'football',
				icon: 'fa fa-futbol-o',
				children: [
					{
						name: t('translations'),
						customTitle: 'translations',
						url: '/smp/translations',
						icon: 'fa fa-globe',
						attributes: {},
						badge: {
							variant: 'info',
						},
					},
					{
						name: t('tournaments'),
						customTitle: 'tournaments',
						url: '/smp/tournaments',
						icon: 'fa fa-trophy',
						attributes: {},
						badge: {
							variant: 'info',
						},
					},
					{
						name: t('tournament_groups'),
						customTitle: 'tournament_groups',
						url: '/smp/tournament-groups',
						icon: 'fa fa-th-list',
						attributes: {},
						badge: {
							variant: 'info',
						},
					},
					{
						name: t('players'),
						customTitle: 'players',
						url: '/smp/players',
						icon: 'fa fa-users',
						attributes: {},
						badge: {
							variant: 'info',
						},
					},
					{
						name: t('teams'),
						customTitle: 'teams',
						url: '/smp/teams',
						icon: 'fa fa-shield',
						attributes: {},
						badge: {
							variant: 'info',
						},
					},
					{
						name: t('coaches'),
						customTitle: 'coaches',
						url: '/smp/coaches',
						icon: 'fa fa-users',
						attributes: {},
					},
					{
						name: t('matches'),
						customTitle: 'matches',
						url: '/smp/matches',
						icon: 'fa fa-soccer-ball-o',
						attributes: {},
						badge: {
							variant: 'info',
						},
					},
				],
			},
			{
				title: true,
				name: t('customization'),
				customTitle: 'customization',
				wrapper: {
					element: '',
					attributes: {},
				},
				class: '',
			},
			{
				name: t('custom_blocks'),
				customTitle: 'custom_blocks',
				url: '/smp/custom-blocks',
				icon: 'fa fa-puzzle-piece',
				attributes: {},
				badge: {
					variant: 'info',
				},
			},
			{
				title: true,
				name: t('integrations'),
				wrapper: {
					element: '',
					attributes: {},
				},
				class: '',
			},
			{
				name: 'Widgets page',
				customTitle: 'widgets-page',
				url: '/smp/widgets-page',
				icon: 'fa fa-server',
			},
			{
				name: 'Fans United',
				customTitle: 'fans-united',
				icon: 'fa fa-futbol-o',
				children: [
					{
						name: 'Top X Manager',
						customTitle: 'topxmanager',
						url: '/smp/topx-manager',
						icon: 'fa fa-globe',
						attributes: {},
						badge: {
							variant: 'info',
						},
					},
					{
						name: 'Match Quiz Manager',
						customTitle: 'match-quiz-manager',
						url: '/smp/match-quiz-manager',
						icon: 'fa fa-globe',
						attributes: {},
						badge: {
							variant: 'info',
						},
					},
					{
						name: 'Leaderboard Manager',
						customTitle: 'leaderboard-manager',
						url: '/smp/leaderboard-manager',
						icon: 'fa fa-globe',
						attributes: {},
						badge: {
							variant: 'info',
						},
					},
					{
						name: 'Classic Quiz Manager',
						customTitle: 'classic-quiz-manager',
						url: '/smp/classic-quiz-manager',
						icon: 'fa fa-globe',
						attributes: {},
						badge: {
							variant: 'info',
						},
					},
					{
						name: 'Either/Or Manager',
						customTitle: 'either-or-manager',
						url: '/smp/either-or-manager',
						icon: 'fa fa-globe',
						attributes: {},
						badge: {
							variant: 'info',
						},
					},
					{
						name: 'Discussions Manager',
						customTitle: 'discussions-manager',
						url: '/smp/discussions-manager',
						icon: 'fa fa-globe',
						attributes: {},
						badge: {
							variant: 'info',
						},
					},
					{
						name: 'Polls Manager',
						customTitle: 'polls-manager',
						url: '/smp/polls-manager',
						icon: 'fa fa-globe',
						attributes: {},
						badge: {
							variant: 'info',
						},
					},
				],
			},
			{
				name: 'Dugout',
				customTitle: 'dugout',
				url: '/smp/dugout/',
				icon: 'fa fa-server',
			},
			{
				name: 'Onnetwork.tv',
				customTitle: 'onnetwork_integration',
				url: '/smp/onnetwork/',
				icon: 'fa fa-server',
				badge: {
					variant: 'info',
				},
			},
			{
				name: 'Playing Surface',
				customTitle: 'playing-surface',
				url: '/smp/playing-surface',
				icon: 'fa fa-server',
				badge: {
					variant: 'info',
				},
			},
			{
				name: t('multisport_widget_configuration'),
				customTitle: 'multisport-widget',
				url: '/smp/multisport-widget',
				icon: 'fas fa-calendar-check',
				badge: {
					variant: 'info',
				},
			},
			// {
			// 	name: 'Dazn',
			// 	url: '#',
			// 	icon: 'fa fa-server',
			// 	attributes: { disabled: true },
			// },
			// {
			// 	name: 'Wyscout',
			// 	url: '#',
			// 	icon: 'fa fa-server',
			// 	attributes: { disabled: true },
			// },
			// {
			// 	name: 'NewsWhip',
			// 	url: '#',
			// 	icon: 'fa fa-server',
			// 	attributes: { disabled: true },
			// },
			// {
			// 	name: 'Matchmetrics',
			// 	url: '#',
			// 	icon: 'fa fa-server',
			// 	attributes: { disabled: true },
			// },
		],
	};
};
